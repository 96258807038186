<template>
<b-card class="p-2" no-body>
    <div
    class="custom-search d-flex justify-content-between align-items-center mb-2 px-2"
    >
    <h2 class="m-0 font-weight-bolder">
        {{ $t("Global.All") }}
        {{ $t("Management.Charges.Charges") }}
    </h2>
        <div class="d-flex">
            <div class="d-flex align-items-center">
            <b-form-input
                v-model="searchTerm"
                :placeholder="$t('Global.Search')"
                type="text"
                class="d-inline-block search"
            />
            </div>
            <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="ml-2 d-flex align-items-center"
            variant="primary"
            @click="addEmptyRow"
            >
            <feather-icon class="mr-1" icon="CreditCardIcon" size="21" />
            {{ $tc('Global.New', 1) }} {{ $t('Management.Charges.Charge') }}
            </b-button>

        </div>
    </div>
    <Table 
    :rows="charges" 
    :search-term="searchTerm"
    @delete="deleteRow"
    @add="addChargeEntry"
    />
</b-card>
</template>

<script>

import { BCard, BButton, BFormInput } from "bootstrap-vue";
import Table from "./components/table.vue";
import deleteEntry from "@/utility/scripts/delete";

export default {

    components:{
        BCard,
        BButton,
        BFormInput,
        Table
    },

    data: () => ({
        charges: [],
        searchTerm: "",
    }),

    async created() {
        this.getCharges();
    },

    methods:{
        async getCharges(){
            try {
                const { data } = await this.$axios.get(`charges`);
                this.charges = data;
            } catch (error) {
                this.$alert.error();
                this.$router.push({ name: "charges" });
            }
        },

        async addEmptyRow() {
            this.charges.push({
                id: "0",
                from: 0,
                to: 0,
                price: 0
            })
        },

        addChargeEntry(entry) {
            this.charges.push(entry);
        },
        async deleteRow(row) {
            try{
                await deleteEntry(this, {
                    id: row.id,
                    article: `${this.$t('Global.Articles_2')}`,
                    subject: `${this.$t("Management.Charges.Charge")}`,
                    endpoint: "charges/",
                });
                this.getCharges();
            } catch (error) {
                // continue regardless of error
            }
        },
    }
}
</script>

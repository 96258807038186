<template>
    <div>
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <div slot="emptystate" class="text-center">
          {{ $t("Global.Emptystate") }}
        </div>
  
        <template slot="table-row" slot-scope="props">
          <!-- Column: Action -->
          <span
            v-if="props.column.field === 'action' && props.row.id !== editRowId"
            class="action"
          >
            <span>
              <feather-icon
                v-b-tooltip.hover.top="
                  $t(
                    `Global.${
                      $can($acl.action.Update, $acl.subjects.Certificates)
                        ? 'EditSubject'
                        : 'ViewSubject'
                    }`,
                    { subject: $t('Management.Charges.Charge') }
                  )
                "
                :icon="
                  $can($acl.action.Create, $acl.subjects.Certificates)
                    ? 'Edit2Icon'
                    : 'EyeIcon'
                "
                size="16"
                class="hover-primary"
                @click="editRowId = props.row.id"
              />
            </span>
            <span
              v-if="$can($acl.action.Delete, $acl.subjects.Certificates)"
              v-b-tooltip.hover.top="
                $t('Global.DeleteSubject', {
                  subject: $t('Management.Charges.Charge'),
                })
              "
            >
              <feather-icon
                icon="TrashIcon"
                size="16"
                class="hover-red"
                @click="$emit('delete', props.row)"
              />
            </span>
          </span>
  
          <span
            v-if="props.column.field === 'action' && props.row.id === editRowId"
            class="action"
          >
            <span v-if="$can($acl.action.Update, $acl.subjects.Charges)">
              <feather-icon
                icon="SaveIcon"
                size="16"
                class="hover-red"
                @click="saveCharge(props)"
              />
            </span>
            <span v-if="$can($acl.action.Update, $acl.subjects.Charges)">
              <feather-icon
                icon="XIcon"
                size="16"
                class="hover-red"
                @click="cancelEdit"
              />
            </span>
          </span>
  
          <!-- Column: Common -->
          <span
            v-if="props.row.id !== editRowId && props.column.field !== 'action'"
          >
            {{ props.formattedRow[props.column.field] }}
          </span>
  
          <span
            v-if="props.row.id === editRowId && props.column.field !== 'action'"
          >
            <b-form-input
              v-if="props.column.field === 'from'"
              v-model="props.row[props.column.field]"
              type="number"
              size="sm"
            />
            <b-form-input
              v-if="props.column.field === 'to'"
              v-model="props.row[props.column.field]"
              type="number"
              size="sm"
            />
            <b-form-input
              v-if="props.column.field === 'price'"
              v-model="props.row[props.column.field]"
              type="number"
              size="sm"
            />
            <b-form-input
              v-else-if="props.column.field === 'title'"
              v-model="props.row[props.column.field]"
              type="text"
              size="sm"
            />
            <select
              v-else-if="props.column.field === 'chargeType'"
              v-model="props.formattedRow[props.column.field]"
              @change="chargeTypeChanged(props)"
            >
              <option
                v-for="(val, index) in ChargeTypesEnum"
                :value="val"
                :key="props.column.field + ' ' + index"
              >
                {{ val }}
              </option>
            </select>



          </span>
        </template>
  
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap px-2 pb-2">
            <div class="d-flex align-items-center mb-0 mt-1 flex-wrap">
              <span class="text-nowrap">
                {{ $t("Global.ShowingOneOf") }}
              </span>
              <v-select
                v-model="pageLength"
                class="mx-1"
                style="min-width: 75px"
                :clearable="false"
                :options="['10', '15', '30']"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap">{{ $t("Global.EntriesShown") }}</span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </template>
  
<script>
  import { ChargeTypesEnum } from "@/data/enums";
  import { BPagination, BFormInput } from "bootstrap-vue";
  import { VueGoodTable } from "vue-good-table";
  import vSelect from 'vue-select'

  
  export default {
    components: {
      VueGoodTable,
      BFormInput,
      BPagination,
      vSelect,
    },
    props: {
      rows: {
        type: Array,
        default: () => [],
      },
      searchTerm: {
        type: String,
        default: () => "",
      }
    },
  
    data() {
      return {
        ChargeTypesEnum,
        pageLength: 30,
        editRowId: "0",
        columns: [
          {
            label: this.$t("Management.Charges.From"),
            field: "from",
            type: 'number'
          },
          {
            label: this.$t("Management.Charges.To"),
            field: "to",
            type: 'number'
          },
          {
            label: this.$t("Management.Charges.Price"),
            field: "price",
            type: 'number'
          },
          {
            label: this.$t("Management.Charges.Title"),
            field: "title",
          },
          {
            label: this.$t('Management.Charges.Type'),
            field: 'chargeType',
          },
          {
            label: this.$t("Global.Action"),
            field: "action",
            sortable: false,
          },
        ],
      };
    },
    
    methods: {
  
      async saveCharge(props) {
        try {
            props.row.from = Number(props.row.from);
            props.row.to = Number(props.row.to);
            props.row.price = Number(props.row.price);
            
            if (props.row.id === "0") {
                const { data } = await this.$axios.post("charges", props.row);
                this.$alert.create(`${data.name}`);
                this.$emit("add", data);
    
                this.rows.splice(
                this.rows.findIndex((row) => row.id === "0"),
                1
                );
            } else {
                const { data } = await this.$axios.patch(
                `charges/${props.row.id}`,
                props.row
                );
    
                Object.keys(data).forEach((key) => {
                props.row[key] = data[key];
                });
                this.$alert.update(data.name);
                this.cancelEdit();
            }
        } catch {
          this.$alert.error();
        }
      },

      chargeTypeChanged(props){
        props.row.chargeType = props.formattedRow.chargeType;
      },
  
      cancelEdit() {
        this.editRowId = "0";
        const index = this.rows.findIndex((row) => row.id === "0");
        if (index > -1) {
          this.rows.splice(index, 1);
        }
      },
      
    },
  };
  </script>
<style lang="scss">
select {
  padding: 0.4rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  &:focus-visible {
    border: 1px solid #d8d6de;
  }
}
</style>
const dialog = async (
  { $bvModal, $i18n, $axios, $alert, $createElement },
  { id, subject, individual, article, endpoint },
) => {
  try {
    const messageVNode = $createElement('div', {
      class: ['p-1'],
      domProps: {
        innerHTML: $i18n.t('Global.DeleteQuestion', { individual, subject, article }),
        style: 'font-size: 16px',
      },
    })

    const choice = await $bvModal.msgBoxConfirm([messageVNode], {
      title: $i18n.t('Global.DeleteSubject', { subject }),
      size: 'sm',
      okVariant: 'primary',
      cancelVariant: 'outline-secondary',
      okTitle: $i18n.t('Global.Delete'),
      cancelTitle: $i18n.t('Global.Retreat'),
      hideHeaderClose: false,
      centered: true,
      'body-class': 'p-2',
    })
    if (!choice) return Promise.reject(new Error('Users aborts'))
  } catch (error) {
    return Promise.reject(error)
  }

  try {
    await $axios.delete(endpoint + id)
    $alert.delete(individual)
    return Promise.resolve(id)
  } catch (error) {
    $alert.error()
    return Promise.reject(error)
  }
}

export default dialog
